import { testRights } from './rights'
import { manager } from './roles'
import { manager1 } from 'app/mocks/data/users/managers/list'

export const lambda1 = {
    id: 2,
    username: 'lambda1',
    email: 'lambda1@e-thik.com',
    last_name: 'RENO',
    first_name: 'Jean',
    is_staff: false,
    profil: {
        client: [manager1],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: true,
        mail_offline: true,
        mail_technical_infos: true,
        mail_high_stock: true,
        mail_low_stock: true,
        mail_dispute: true,
    },
    groups: [manager, testRights],
}
