import * as distributorDiagnostics from './distributors'
import * as fridgeWeightDiagnostics from './fridgesWeight'
import * as lockerColdDiagnostics from './lockersCold'
import * as lockerDryDiagnostics from './lockersDry'
import * as terminalDiagnostics from './terminals'

export const allDiagnostics = [
    ...Object.values(distributorDiagnostics),
    ...Object.values(fridgeWeightDiagnostics),
    ...Object.values(lockerColdDiagnostics),
    ...Object.values(lockerDryDiagnostics),
    ...Object.values(terminalDiagnostics),
]
