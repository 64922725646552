export const category1 = {
    id: 1,
    name: 'Industriel',
    priority: 1,
}

export const category2 = {
    id: 2,
    name: 'Sandwicherie',
    priority: 1,
}

export const category3 = {
    id: 3,
    name: 'Boulangerie',
    priority: 1,
}

export const category4 = {
    id: 4,
    name: 'Grande surface',
    priority: 1,
}

export const category5 = {
    id: 5,
    name: 'Administration publique',
    priority: 1,
}

export const category6 = {
    id: 6,
    name: 'Restaurant',
    priority: 1,
}

export const category7 = {
    id: 7,
    name: 'Traiteur',
    priority: 1,
}
