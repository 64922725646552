import { machineType } from 'app/utils/enums'

export const lockerDryStandard1 = {
    id: 401,
    name: 'Locker dry 1 Mock',
    category: machineType.Enum.LOCKER_DRY,
    description: 'abc',
    stock_usage_percentage: 3,
    number_of_products: 9,
    max_products: 300,
    provider: 'E-thik',
    software_version: '1.14',
    build_date: '2022-08-29',
    installation_date: '2022-08-29',
    distributor_volume: 300,
    connexion_type: 'et',
    paiement_method: 'ts',
    private_id: '455',
    mode: 'Vente',
    enabled: true,
    online: false,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 90,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 92,
    components: [],
}

export const lockerDryStandard2 = {
    id: 402,
    name: 'Locker dry 2 Mock',
    category: machineType.Enum.LOCKER_DRY,
    description: 'abc',
    stock_usage_percentage: 3,
    number_of_products: 9,
    max_products: 300,
    provider: 'E-thik',
    software_version: '1.14',
    build_date: '2022-08-29',
    installation_date: '2022-08-29',
    distributor_volume: 300,
    connexion_type: 'et',
    paiement_method: 'ts',
    private_id: '455',
    mode: 'Vente',
    enabled: true,
    online: false,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 90,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 92,
    components: [],
}

export const lockerDryStandard3 = {
    id: 403,
    name: 'Locker dry 3 Mock',
    category: machineType.Enum.LOCKER_DRY,
    description: 'abc',
    stock_usage_percentage: 3,
    number_of_products: 9,
    max_products: 300,
    provider: 'E-thik',
    software_version: '1.14',
    build_date: '2022-08-29',
    installation_date: '2022-08-29',
    distributor_volume: 300,
    connexion_type: 'et',
    paiement_method: 'ts',
    private_id: '455',
    mode: 'Vente',
    enabled: true,
    online: false,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 90,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    paygreen_shop_id: null,
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 92,
    components: [],
}
