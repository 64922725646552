import createManager from './createManager'
import deleteManager from './deleteManager'
import getAllManagers from './getAllManagers'
import getManager from './getManager'
import updateManager from './updateManager'

const managers = [
    createManager,
    deleteManager,
    getAllManagers,
    getManager,
    updateManager,
]

export default managers
