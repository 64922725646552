export const MAX_IMAGE_SIZE = 1_024 * 1_024 * 2
export const PAGINATION_LIMIT = 20

export const API_ENDPOINTS = {
    ALLERGENS: 'allergens/',
    BLOCKED_CARDS: 'blocked_cards/',
    EMAIL_PARAMETERS: 'email_parameters/',
    MACHINES_CATEGORIES: 'categories_machines/',
    PRODUCTS: 'products/',
    PRODUCTS_CATEGORIES: 'categories_products/',
    PRODUCTS_SUB_CATEGORIES: 'sub_categories_products/',
    PRODUCTS_IMPORT: 'import/products/',
    REFUND: 'refund/',
    SALES: 'sales/',
    SITES: 'sites/',
    SITES_TYPES: 'site_types/',
    STATS_STOCK: 'stats_stock',
    TICKET_PARAMETERS: 'ticket_parameters/',
    UPLOAD_SIGNATURE: 'images_sign_upload/',
    ZONES: 'zones/',

    auth: {
        ME: 'me',
        LOGIN: 'token/',
        REFRESH_TOKENS: 'token/refresh/',
    },

    managers: {
        LIST: 'clients/',

        categories: {
            LIST: 'client_category',
        },
    },

    park: {
        CLIENTS: 'site_customer/',
        DIAGNOSTIC: 'diagnostic/',
        MACHINE_RELATIONS: 'machines_grid/',
        MACHINES: 'machines/',
        PLANOGRAMS: 'machines_grid_items/',
    },

    products: {
        SUPPLIERS: 'supplier_products/',
    },

    users: {
        KONSOLE: 'users/',
        KONSOLE_IMPERSONATE: 'users/impersonate/',
        RIGHTS: 'users_groups/',
    },
}

export const urls = {
    ERROR_404: '/404',
    ERROR_500: '/500',
    HOMEPAGE: '/dashboard',
    PROFILE: '/profile',

    auth: {
        IMPERSONATE: '/auth/impersonate',
        LOGIN: '/auth/login',
        RESET_PASSWORD: '/password-reset',
    },

    operations: {
        DELIVERY_ORDERS: '/operations/delivery-orders',
        DESTOCKING: '/operations/destocking',
        ENCODED_PRODUCTS: '/operations/encoded-products',
        INCIDENTS: '/operations/incidents',
        INDEX: '/operations',
        INTERVENTIONS: '/operations/interventions',
        RABBITMQ_INCIDENTS: '/operations/rabbitmq-incidents',
    },

    park: {
        INDEX: '/park',
        MACHINES: '/park/machines',

        clients: {
            CREATE: '/park/clients/create',
            DETAILS: '/park/clients/:id',
            LIST: '/park/clients',
        },

        planograms: {
            INDEX: '/park/planograms',
            CREATE: '/park/planograms/create/:id',
            DETAILS: '/park/planograms/:id',
            PRODUCT_INFO: '/park/planograms/:id/product-info',
            TECHNICAL_DATA: '/park/planograms/:id/technical-data',
        },

        sites: {
            INDEX: '/park/sites',
            DETAILS: '/park/sites/:id',
            MACHINE_MARKETING:
                '/park/sites/:idSite/machine-marketing/:idMachine',
            MACHINE_STOCK: '/park/sites/:idSite/machine-stock/:idMachine',
            TECHNICAL_DATA:
                '/park/sites/:idSite/machine-technical-data/:idMachine',
        },
    },

    products: {
        INDEX: '/products',
        LIST: '/products/list',
        PRICE_TABLES: '/products/price-tables',
        REDUCTIONS: '/products/reductions',
        STATS: '/products/stats',

        suppliers: {
            CREATE: '/products/suppliers/create',
            DETAILS: '/products/suppliers/:id',
            LIST: '/products/suppliers',
        },
    },

    reports: {
        INDEX: '/reports',
        SUPPLY: '/reports/supply',
    },

    settings: {
        CUSTOMISATIONS: '/settings/customisations',
        EMAILS: '/settings/emails',
        FORMS: '/settings/forms',
        INDEX: '/settings',
        TICKETS: '/settings/tickets',
    },

    transactions: {
        BLOCKED_CARDS: '/transactions/blocked-cards',
        INDEX: '/transactions',
        REFILLS: '/transactions/refills',
        REFUNDS: '/transactions/refunds',
        SALES: '/transactions/sales',
    },

    users: {
        DELIVERY_MEN: '/users/delivery-men',
        INDEX: '/users',
        KONVIVES: '/users/konvives',
        MANAGERS: '/users/managers',
        PDA: '/users/pda',
        PROFILES: '/users/profiles',
        TECHNICIANS: '/users/technicians',

        konsole: {
            CREATE: '/users/konsole/create',
            DETAILS: '/users/konsole/:id',
            LIST: '/users/konsole',
        },

        managers: {
            CREATE: '/users/managers/create',
            DETAILS: '/users/managers/:id',
            LIST: '/users/managers',
        },
    },
}

export const links = {
    TICKETING: 'https://e-thik.odoo.com',
}

/** Make a pause with the time given (in milliseconds). */
export const pause = async (time) => {
    await new Promise((resolve) => setTimeout(resolve, time))
}

/** Know if the value passed is a number or not. */
export const isNumeric = (numberToTest) =>
    (typeof numberToTest === 'number' ||
        (typeof numberToTest === 'string' && numberToTest.trim() !== '')) &&
    !isNaN(numberToTest)

/** Konsole users roles availables. */
export const roles = {
    DELIVERY_MEN: 'Approvisionneur',
    MANAGER: 'Gestionnaire',
    TECHNICIAN: 'Technicien',
}
