import * as managers from 'app/mocks/data/users/managers/list'

export const client1 = {
    id: 1,
    name: 'Carot',
    client: managers.manager1,
    address: '15 rue de la Fourchette',
    postal_code: '91010',
    city: 'Doul',
    sign_first_name: 'Jean-Jacques',
    sign_last_name: 'CHEVOTET',
    email: 'client@carot.com',
    phone_number: '0946589731',
    sign_date: '2023-11-03',
    num_contract: '2135',
    duration_contract: 24,
}

export const client2 = {
    id: 2,
    name: 'Ethanol',
    client: managers.manager1,
    sign_first_name: 'Jordan',
    sign_last_name: 'NERI',
    email: 'client@ethanol.com',
    phone_number: '0946589731',
}

export const client3 = {
    id: 3,
    name: 'EDF',
    client: managers.manager1,
    sign_first_name: 'Gilbert',
    sign_last_name: 'BOURGIGNON',
    email: 'client@edf.com',
    phone_number: '0946589731',
}

export const client4 = {
    id: 4,
    name: 'Kellogs',
    client: managers.manager1,
    sign_first_name: 'Louis',
    sign_last_name: 'ALARD',
    email: 'client@kellogs.com',
    phone_number: '0946589731',
}

export const client5 = {
    id: 5,
    name: 'Renault',
    client: managers.manager1,
    sign_first_name: 'Maéva',
    sign_last_name: 'DOISNEAU',
    email: 'client@renault.com',
    phone_number: '0946589731',
}

export const client6 = {
    id: 6,
    name: 'BTP',
    client: managers.manager1,
    sign_first_name: 'Fabien',
    sign_last_name: 'LAVAUD',
    email: 'client@btp.com',
    phone_number: '0946589731',
}

export const client7 = {
    id: 7,
    name: 'SCI MANUFACTURE',
    client: managers.manager1,
    sign_first_name: 'Georges',
    sign_last_name: 'DUBOS',
    email: 'client@sci-manufacture.com',
    phone_number: '0946589731',
}

export const client8 = {
    id: 8,
    name: 'Boulanger',
    client: managers.manager1,
    sign_first_name: 'Edgar',
    sign_last_name: 'BRASSARD',
    email: 'client@boulanger.com',
    phone_number: '0946589731',
}

export const client9 = {
    id: 9,
    name: 'Euromarket',
    client: managers.manager1,
    sign_first_name: 'Sandrine',
    sign_last_name: 'CHOFFARD',
    email: 'client@euromarket.com',
    phone_number: '0946589731',
}

export const client10 = {
    id: 10,
    name: 'Boulangerie Anne',
    client: managers.manager1,
    sign_first_name: 'Zacharie',
    sign_last_name: 'CHAGNON',
    email: 'client@boulangerie-anne.com',
    phone_number: '0946589731',
}

export const client11 = {
    id: 11,
    name: 'Garage du Nord',
    client: managers.manager2,
    sign_first_name: 'Astrid',
    sign_last_name: 'DUBUISSON',
    email: 'client@garage-du-nord.com',
    phone_number: '0946589731',
}

export const client12 = {
    id: 12,
    name: 'Mauboussin',
    client: managers.manager2,
    sign_first_name: 'Débora',
    sign_last_name: 'MOSSÉ',
    email: 'client@mauboussin.com',
    phone_number: '0946589731',
}

export const client13 = {
    id: 13,
    name: 'ECF',
    client: managers.manager2,
    sign_first_name: 'Abélard',
    sign_last_name: 'KLÉBER',
    email: 'client@ecf.com',
    phone_number: '0946589731',
}

export const client14 = {
    id: 14,
    name: 'Laura Todd',
    client: managers.manager2,
    sign_first_name: 'Elise',
    sign_last_name: 'BERTHELOT',
    email: 'client@laura-todd.com',
    phone_number: '0946589731',
}

export const client15 = {
    id: 15,
    name: 'ASF',
    client: managers.manager2,
    sign_first_name: 'Djeferson',
    sign_last_name: 'BARDIN',
    email: 'client@asf.com',
    phone_number: '0946589731',
}

export const client16 = {
    id: 16,
    name: 'Maison du monde',
    client: managers.manager3,
    sign_first_name: 'Clément',
    sign_last_name: 'HÉBRAS',
    email: 'client@maison-du-monde.com',
    phone_number: '0946589731',
}

export const client17 = {
    id: 17,
    name: 'Chez Paul',
    client: managers.manager3,
    sign_first_name: 'Jacqueline',
    sign_last_name: 'FAVRE',
    email: 'client@chez-paul.com',
    phone_number: '0946589731',
}

export const client18 = {
    id: 18,
    name: 'Au Pain Du Jour',
    client: managers.manager3,
    sign_first_name: 'Jacob',
    sign_last_name: 'BERGER',
    email: 'client@aupaindujour.com',
    phone_number: '0946589731',
}

export const client19 = {
    id: 19,
    name: 'Gastronomie',
    client: managers.manager3,
    sign_first_name: 'Clara',
    sign_last_name: 'MICHAUX',
    email: 'client@gastronomie.com',
    phone_number: '0946589731',
}

export const client20 = {
    id: 20,
    name: 'MangerBio',
    client: managers.manager3,
    sign_first_name: 'Benjamin',
    sign_last_name: 'LEVASSEUR',
    email: 'client@mangerbio.com',
    phone_number: '0946589731',
}

export const client21 = {
    id: 21,
    name: 'Pain Artisanal',
    client: managers.manager4,
    sign_first_name: 'Arnaud',
    sign_last_name: 'BLONDEAU',
    email: 'client@pain-artisanal.com',
    phone_number: '0946589731',
}
