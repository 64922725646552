import { z } from 'zod'

/** Enum  planogram selection error */
export const ErrorTypeSelection = z.enum(['EMPTY', 'MAPPING', 'MOTOR', 'NAME'])

/** Get translation key of planogram selection error. */
export const ErrorTypeSelectionTranslate = {
    EMPTY: 'locationErrorEmpty',
    MAPPING: 'locationErrorDuplicate',
    MOTOR: 'locationErrorMotor',
    NAME: 'locationErrorPlanogramName',
}

/** Locale enum. */
export const Locale = z.enum(['en-GB', 'es-ES', 'fr-FR'])

/** Enum machine type. */
export const machineType = z.enum([
    'DISTRIBUTOR',
    'FRIDGE_RFID',
    'FRIDGE_WEIGHT',
    'LOCKER_COLD',
    'LOCKER_DRY',
    'TERMINAL',
])

/** Machine order available. */
export const OrderMachine = z.enum(['A', 'B', 'C', 'D', 'E', 'F'])

/** Get translation key of machine type. */
export const machineTypeTranslate = {
    DISTRIBUTOR: 'distributor',
    FRIDGE_RFID: 'fridgeRfid',
    FRIDGE_WEIGHT: 'fridgeWeight',
    LOCKER_COLD: 'lockerCold',
    LOCKER_DRY: 'lockerDry',
    TERMINAL: 'terminal',
}

/** Enum planogram status. */
export const planogramStatus = z.enum(['ACTIVE', 'INACTIVE', 'PENDING'])

/** Get translation key of planogram status. */
export const planogramStatusTranslate = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    PENDING: 'pending',
}

/** Enum planogram component mode. */
export const planogramMode = z.enum([
    'CREATE',
    'DETAILS',
    'PRODUCT_INFO',
    'TECHNICAL_DATAS',
])

/** Enum planogram component actions. */
export const planogramActions = z.enum([
    'MANAGEMENT_TRAY',
    'MANAGEMENT_SELECTION',
    'MAPPING',
    'MOTOR',
    'PRODUCT_CHANGE',
    'SIZE',
    'STOCK',
])

/** Enum type for refund type */
export const RefundType = z.enum(['INFORMATION', 'RECREDITATION'])

/** Get translation key of refund type */
export const RefundTypeTranslate = {
    INFORMATION: 'informative',
    RECREDITATION: 'recreditation',
}

/** Refund status available. */
export const StatusRefund = z.enum(['APPROVED', 'IN_PROGRESS', 'REFUSED'])

/** Get the translation key of the refund status. */
export const StatusRefundTranslate = {
    APPROVED: 'approved',
    IN_PROGRESS: 'inProgress',
    REFUSED: 'refused',
}

/** Enum stock type for quantity product. */
export const StockType = z.enum(['RECOMMENDED', 'MAXIMUM'])

/** Get translation key of stock type. */
export const StockTypeTranslate = {
    RECOMMENDED: 'recommended',
    MAXIMUM: 'maximum',
}
