import * as planogramsShort from './short'

export const planogram1 = {
    ...planogramsShort.planogramShort1,
    grid: [],
}

export const planogram2 = {
    ...planogramsShort.planogramShort2,
    grid: [],
}

export const planogram3 = {
    ...planogramsShort.planogramShort3,
    grid: [],
}

export const planogram4 = {
    ...planogramsShort.planogramShort4,
    grid: [],
}

export const planogram5 = {
    ...planogramsShort.planogramShort5,
    grid: [],
}

export const planogram6 = {
    ...planogramsShort.planogramShort6,
    grid: [],
}

export const planogram7 = {
    ...planogramsShort.planogramShort7,
    grid: [],
}

export const planogram8 = {
    ...planogramsShort.planogramShort8,
    grid: [],
}

export const planogram9 = {
    ...planogramsShort.planogramShort9,
    grid: [],
}

export const planogram10 = {
    ...planogramsShort.planogramShort10,
    grid: [],
}
