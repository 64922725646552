import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allSuppliers } from 'app/mocks/data/products/suppliers'
import { API_ENDPOINTS } from 'app/utils/constants'

const updateSupplier = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.products.SUPPLIERS}:id/`,
    async ({ request, params }) => {
        const data = await request.json()
        const { id } = params

        const supplierFound = allSuppliers.find(
            (supplier) => supplier.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!supplierFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const supplierToReturn = {
            ...data,
            ...supplierFound,
        }

        return HttpResponse.json(supplierToReturn)
    },
)

export default updateSupplier
