import { manager1 } from 'app/mocks/data/users/managers/list'

export const admin1 = {
    id: 1,
    username: 'admin1',
    email: 'admin1@e-thik.com',
    last_name: 'DUPONT',
    first_name: 'Louis',
    is_staff: true,
    profil: {
        client: [manager1],
        avatar: 'https://apis-dev.e-thik.com/media/avatars/default.png',
        mail_alarm: true,
        mail_dlc: true,
        mail_offline: true,
        mail_technical_infos: true,
        mail_high_stock: true,
        mail_low_stock: true,
        mail_dispute: true,
    },
    groups: [],
}
