import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    column: {
        flexDirection: 'column',
    },
})

export const Flex = ({ className = '', column, ...props }) => {
    const classes = useStyles()

    return (
        <div
            className={`
        ${classes.root}
        ${column ? classes.column : ''}
        ${className}
      `}
            {...props}
        />
    )
}
