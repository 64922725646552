import createClient from './createClient'
import deleteClient from './deleteClient'
import getAllClients from './getAllClients'
import getClient from './getClient'
import updateClient from './updateClient'

const clients = [
    createClient,
    deleteClient,
    getAllClients,
    getClient,
    updateClient,
]

export default clients
