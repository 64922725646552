import { http, HttpResponse } from 'msw'

import {
    allMachineRelations,
    allMachineRelationsShort,
} from 'app/mocks/data/park/machineRelations'
import { API_ENDPOINTS, isNumeric } from 'app/utils/constants'

const getAllMachineRelations = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.park.MACHINE_RELATIONS}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const terminalFilter = url.searchParams.get('machine__id')
        const shortFilter = url.searchParams.get('get_sub_machines')

        let allMachineRelationsFiltered =
            shortFilter === 'true'
                ? allMachineRelationsShort
                : allMachineRelations

        // Terminal filter
        if (!!terminalFilter) {
            allMachineRelationsFiltered = allMachineRelationsFiltered.filter(
                (machineRelation) =>
                    machineRelation.machine.id.toString() === terminalFilter,
            )
        }

        // Pagination
        const limitArray =
            allMachineRelationsFiltered.length > limit
                ? offset + limit
                : allMachineRelationsFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allMachineRelationsFiltered.slice(offset, limitArray)
                : allMachineRelationsFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allMachineRelationsFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allMachineRelationsFiltered.length,
            next,
            results,
        })
    },
)

export default getAllMachineRelations
