import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import cookies from 'js-cookie'
import { Dropdown } from 'react-bootstrap'
import { ButtonBase, withStyles } from '@material-ui/core'
import { MdSupportAgent } from 'react-icons/md'

import { Dialog } from 'app/ui'
import LogoutButton from './LogoutButton'
import { CustomerService } from 'app/components/app/Header/CustomerService'
import axiosInstance from 'axiosApi'
import i18n from 'i18n'
import { urls } from 'app/utils/constants'
import { various } from 'app/utils/theme'
import LogoKonsole from 'assets/images/Logo_Konsole.svg'

const useStyles = {
    button: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginRight: various.padding,
        padding: '5px',
        borderRadius: various.borderRadius,
    },
}

const languagesTable = [
    {
        country_code: 'en',
        flag_code: 'gb',
        language_name: 'English',
    },
    {
        country_code: 'fr',
        flag_code: 'fr',
        language_name: 'French',
    },
    {
        country_code: 'es',
        flag_code: 'es',
        language_name: 'Spanish',
    },
]

class Navbar extends Component {
    state = {
        country_code: cookies.get('i18next') || 'en',
        flagCode:
            languagesTable.find(
                (l) => l.country_code === (cookies.get('i18next') || 'en'),
            ).flag_code || 'us',
        languageName:
            languagesTable.find(
                (l) => l.country_code === (cookies.get('i18next') || 'en'),
            ).language_name || 'English',
        user: {},
        isOpenDialog: false,
    }

    componentDidMount() {
        this.getMe()
    }

    getMe = async () => {
        if (this.props.authed) {
            try {
                const response = await axiosInstance.get('/me')
                this.setState({
                    user: response.data,
                })
            } catch (err) {
                console.error(err)
            }
        }
    }

    navigateProfile() {
        window.location.href = urls.PROFILE
    }

    navigateIncidents() {
        window.location.href = urls.operations.INCIDENTS
    }

    changeLanguage(lng) {
        this.setState({
            country_code: lng,
            flagCode: languagesTable.find((l) => l.country_code === lng)
                .flag_code,
            languageName: languagesTable.find((l) => l.country_code === lng)
                .language_name,
        })
        i18n.changeLanguage(lng)
        cookies.set('i18next', lng)
    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active')
    }

    async handleLogout() {
        try {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')

            this.props.history.push(urls.auth.LOGIN)
        } catch (e) {
            console.error(e)
        }
    }

    async handleCancel() {
        this.props.history.push(urls.HOMEPAGE)
    }

    render() {
        const { classes } = this.props
        const username = localStorage.getItem('username')

        return (
            <nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
                <div className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'>
                    <Link
                        className='navbar-brand brand-logo'
                        to={urls.HOMEPAGE}
                    >
                        <img
                            src={LogoKonsole}
                            alt='logo'
                        />
                    </Link>
                    <Link
                        className='navbar-brand brand-logo-mini'
                        to={urls.HOMEPAGE}
                    >
                        <img
                            src={require('../../assets/images/logo-mini.png')}
                            alt='logo'
                        />
                    </Link>
                </div>
                <div className='navbar-menu-wrapper d-flex align-items-stretch'>
                    <button
                        className='navbar-toggler navbar-toggler navbar-toggler-right align-self-center'
                        type='button'
                        onClick={() =>
                            document.body.classList.toggle('sidebar-icon-only')
                        }
                    >
                        <span className='mdi mdi-menu'></span>
                    </button>
                    <div className='d-flex align-items-center h-100'>
                        <Trans>Admin Console E-Thik</Trans>
                    </div>

                    <ul className='navbar-nav navbar-nav-right'>
                        {/* Customer service */}
                        <ButtonBase
                            className={classes.button}
                            onClick={() => {
                                this.setState({ isOpenDialog: true })
                            }}
                        >
                            <MdSupportAgent size='1.5rem' />

                            <Trans>customerService</Trans>
                        </ButtonBase>

                        {/* Dialog customer service */}
                        <Dialog
                            open={this.state.isOpenDialog}
                            onClose={() => {
                                this.setState({ isOpenDialog: false })
                            }}
                        >
                            <CustomerService />
                        </Dialog>

                        <li className='nav-item'>
                            <Dropdown alignRight>
                                <Dropdown.Toggle className='nav-link count-indicator hide-carret'>
                                    <i className='mdi mdi-bell-outline'></i>
                                    <span className='count-symbol bg-danger'></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-menu navbar-dropdown preview-list'>
                                    <h6 className='p-3 mb-0 bg-primary text-white py-4'>
                                        <Trans>Notifications</Trans>
                                    </h6>
                                    <div className='dropdown-divider'></div>

                                    <div className='dropdown-divider'></div>
                                    <h6
                                        className='p-3 mb-0 text-center cursor-pointer'
                                        onClick={() => this.navigateIncidents()}
                                    >
                                        <Trans>See all notifications</Trans>
                                    </h6>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        <li className='nav-item nav-profile nav-language'>
                            <Dropdown alignRight>
                                <Dropdown.Toggle className='nav-link count-indicator'>
                                    <div className='nav-profile-text'>
                                        <p className='mb-1 text-black'>
                                            {username}
                                        </p>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='preview-list navbar-dropdown'>
                                    <div className='p-2'>
                                        <h5 className='dropdown-header text-uppercase pl-2 text-dark'>
                                            <Trans>User Options</Trans>
                                        </h5>
                                        <Dropdown.Item
                                            className='dropdown-item d-flex align-items-center justify-content-between'
                                            onClick={() =>
                                                this.navigateProfile()
                                            }
                                        >
                                            <span>
                                                <Trans>Profile</Trans>
                                            </span>
                                            <span className='p-0'>
                                                <i className='mdi mdi-account-outline ml-1'></i>
                                            </span>
                                        </Dropdown.Item>
                                        <Dropdown>
                                            <Dropdown.Toggle className='nav-link count-indicator no-toggle '>
                                                <span
                                                    style={{
                                                        marginLeft: '4px',
                                                    }}
                                                >
                                                    <Trans>Language</Trans>
                                                </span>
                                                <div className='alignRight'>
                                                    <div className='nav-language-icon'>
                                                        <i
                                                            className={`flag-icon flag-icon-${this.state.flagCode}`}
                                                            title='us'
                                                            id='us'
                                                        ></i>
                                                    </div>
                                                    <div className='nav-language-text marginTop'>
                                                        <p className='mb-1 text-black'>
                                                            <Trans>
                                                                {
                                                                    this.state
                                                                        .languageName
                                                                }
                                                            </Trans>
                                                        </p>
                                                    </div>
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='preview-list navbar-dropdown'>
                                                <Dropdown.Item
                                                    className='dropdown-item d-flex align-items-center'
                                                    onClick={() =>
                                                        this.changeLanguage(
                                                            'en',
                                                        )
                                                    }
                                                >
                                                    <div className='nav-language-icon mr-2'>
                                                        <i
                                                            className='flag-icon flag-icon-gb'
                                                            title='GB'
                                                            id='gb'
                                                        ></i>
                                                    </div>
                                                    <div className='nav-language-text'>
                                                        <p className='mb-1 text-black'>
                                                            <Trans>
                                                                English
                                                            </Trans>
                                                        </p>
                                                    </div>
                                                </Dropdown.Item>
                                                <div className='dropdown-divider'></div>
                                                <Dropdown.Item
                                                    className='dropdown-item d-flex align-items-center'
                                                    onClick={() =>
                                                        this.changeLanguage(
                                                            'fr',
                                                        )
                                                    }
                                                >
                                                    <div className='nav-language-icon mr-2'>
                                                        <i
                                                            className='flag-icon flag-icon-fr'
                                                            title='FR'
                                                            id='fr'
                                                        ></i>
                                                    </div>
                                                    <div className='nav-language-text'>
                                                        <p className='mb-1 text-black'>
                                                            <Trans>
                                                                French
                                                            </Trans>
                                                        </p>
                                                    </div>
                                                </Dropdown.Item>
                                                <div className='dropdown-divider'></div>
                                                <Dropdown.Item
                                                    className='dropdown-item d-flex align-items-center'
                                                    onClick={() =>
                                                        this.changeLanguage(
                                                            'es',
                                                        )
                                                    }
                                                >
                                                    <div className='nav-language-icon mr-2'>
                                                        <i
                                                            className='flag-icon flag-icon-es'
                                                            title='ES'
                                                            id='es'
                                                        ></i>
                                                    </div>
                                                    <div className='nav-language-text'>
                                                        <p className='mb-1 text-black'>
                                                            <Trans>
                                                                Spanish
                                                            </Trans>
                                                        </p>
                                                    </div>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <div
                                            role='separator'
                                            className='dropdown-divider'
                                        ></div>
                                        <h5 className='dropdown-header text-uppercase  pl-2 text-dark mt-2'>
                                            <Trans>Actions</Trans>
                                        </h5>

                                        <div className='logout-button'>
                                            <LogoutButton />
                                        </div>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default withStyles(useStyles)(Navbar)
