import { http, HttpResponse } from 'msw'
import { API_ENDPOINTS } from 'app/utils/constants'

import { allSites } from 'app/mocks/data/park/sites'

const getAllSites = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.SITES}`,
    () => {
        return HttpResponse.json({
            count: allSites.length,
            next: false,
            results: allSites,
        })
    },
)

export default getAllSites
