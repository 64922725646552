import { IconButton, makeStyles } from '@material-ui/core'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import React from 'react'
import { Trans } from 'react-i18next'
import palette from '../../assets/styles/_palette.scss'

const useStyles = makeStyles((theme) => ({
    iconTop: {
        backgroundColor: palette.client1,
        color: palette.client2,
        marginLeft: '12px',
    },
}))

const Footer = (props) => {
    const hangleScrollTop = () => {
        window.scrollTo(0, 0)
    }

    const classes = useStyles()

    return (
        <footer className='footer positionFixedFooter'>
            <div className='container-fluid'>
                <div className='d-sm-flex justify-content-center justify-content-sm-between py-2 w-100'>
                    {/* <img src={require("../../assets/images/logo-ethik-footer@3x.png")} className="logoFooter" /> */}
                    <div></div>
                    <span className='float-none float-sm-right d-block mt-1 mt-sm-0 text-center'>
                        <Trans>Scroll on top of the page</Trans>
                        <IconButton
                            className={classes.iconTop}
                            onClick={() => {
                                hangleScrollTop()
                            }}
                        >
                            <KeyboardArrowUpIcon />
                        </IconButton>
                    </span>
                </div>
            </div>
        </footer>
    )
}

export default Footer
