import * as fridgesWeightStandard from 'app/mocks/data/park/machines/standard/fridgesWeight'

export const fridgeWeightDiagnostic1 = {
    machine: {
        id: fridgesWeightStandard.fridgeWeightStandard1.id,
        type: fridgesWeightStandard.fridgeWeightStandard1.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
    power: {
        is_using_power_ups: true,
        last_loss_power: '2022-12-27 08:26:49.219717',
        last_hearbeat_power: '2022-12-27 08:26:49.219717',
    },
}

export const fridgeWeightDiagnostic2 = {
    machine: {
        id: fridgesWeightStandard.fridgeWeightStandard2.id,
        type: fridgesWeightStandard.fridgeWeightStandard2.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2020-12-22 08:26:49.219717',
        last_heartbeat_connection: '2012-12-27 08:26:49.219717',
    },
    power: {
        is_using_power_ups: true,
        last_loss_power: '2023-12-27 08:26:49.219717',
        last_hearbeat_power: '2020-12-27 08:26:49.219717',
    },
}

export const fridgeWeightDiagnostic3 = {
    machine: {
        id: fridgesWeightStandard.fridgeWeightStandard3.id,
        type: fridgesWeightStandard.fridgeWeightStandard3.category,
    },
    internet: {
        is_online: false,
        last_loss_connection: '2018-12-27 08:26:49.219717',
        last_heartbeat_connection: '2002-12-27 08:26:49.219717',
    },
}
