import createSupplier from './suppliers/createSupplier'
import deleteSupplier from './suppliers/deleteSupplier'
import getAllSuppliers from './suppliers/getAllSuppliers'
import getSupplier from './suppliers/getSupplier'
import updateSupplier from './suppliers/updateSupplier'

const products = [
    createSupplier,
    deleteSupplier,
    getAllSuppliers,
    getSupplier,
    updateSupplier,
]

export default products
