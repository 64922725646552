import * as distributorsStandard from 'app/mocks/data/park/machines/standard/distributors'
import * as sites from 'app/mocks/data/park/sites/linked'

export const distributor1 = {
    ...distributorsStandard.distributorStandard1,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}

export const distributor2 = {
    ...distributorsStandard.distributorStandard2,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}
