import * as distributors from './linked/distributors'
import * as fridgesWeight from './linked/fridgesWeight'
import * as lockersCold from './linked/lockersCold'
import * as lockersDry from './linked/lockersDry'
import * as terminals from './linked/terminals'

export const allMachines = [
    ...Object.values(distributors),
    ...Object.values(fridgesWeight),
    ...Object.values(lockersCold),
    ...Object.values(lockersDry),
    ...Object.values(terminals),
].sort((previous, current) => previous.name.localeCompare(current.name))
