import { useRecoilState } from 'recoil'

import { GlobalSnackbarAtom, globalSnackberAtom } from 'app/recoil/atoms'

export const useGlobalSnackbarStore = () => {
    /** @type {[GlobalSnackbarAtom, SetterOrUpdater<GlobalSnackbarAtom>]} */
    const [globalSnackbar, setGlobalSnackbar] =
        useRecoilState(globalSnackberAtom)

    /**
     * Initialise the setter of show value.
     * @param {SetterOrUpdater<boolean>} setShow Setter to show global snackbar
     * @return {void}
     */
    const initGlobalSnackbar = (setShow) => {
        setGlobalSnackbar((oldGlobalSnackbar) => ({
            ...oldGlobalSnackbar,
            setShow,
        }))
    }

    /**
     * Display the global snackbar depending on the given data.
     * @param {GlobalSnackbarAtom['variant']} variant Value of the variant
     * @param {GlobalSnackbarAtom['text']} text Text to display
     * @return {void}
     */
    const showGlobalSnackbar = (variant, text) => {
        setGlobalSnackbar((oldGlobalSnackbar) => ({
            ...oldGlobalSnackbar,
            variant,
            text,
        }))

        globalSnackbar.setShow(true)
    }

    return {
        initGlobalSnackbar,
        showGlobalSnackbar,
    }
}
