import React from 'react'
import { makeStyles } from '@material-ui/core'

import { various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        '&> tr > th:first-child': {
            borderTopLeftRadius: various.borderRadius,
        },

        '&> tr > th:last-child': {
            borderTopRightRadius: various.borderRadius,
        },
    },
})

export const TableHeader = ({ className = '', ...props }) => {
    const classes = useStyles()

    return (
        <thead
            className={`${classes.root} ${className}`}
            {...props}
        />
    )
}
