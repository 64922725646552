import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { API_ENDPOINTS } from 'app/utils/constants'

const createManager = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.managers.LIST}`,
    async ({ request }) => {
        const data = await request.json()

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        const managerCreated = {
            id: 999,
            ...data,
        }

        return HttpResponse.json(managerCreated, {
            status: StatusCodes.CREATED,
        })
    },
)

export default createManager
