import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import ConfirmDialog from 'app/ConfirmDialog'
import { urls } from 'app/utils/constants'

export default function LogoutButton() {
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: '',
    })

    const onDelete = () => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')

        window.location.href = urls.auth.LOGIN
    }

    return (
        <>
            <div
                className='cursor-pointer'
                onClick={() => {
                    onDelete()
                }}
            >
                <Trans>Log Out</Trans>
                <ExitToAppIcon className='icon-logout-button' />
            </div>

            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>
    )
}
