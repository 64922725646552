import * as sites from 'app/mocks/data/park/sites/linked'
import * as lockersColdStandard from 'app/mocks/data/park/machines/standard/lockersCold'

export const lockerCold1 = {
    ...lockersColdStandard.lockerColdStandard1,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}

export const lockerCold2 = {
    ...lockersColdStandard.lockerColdStandard2,
    site: {
        ...sites.site11,
        client: sites.site11.client.id,
    },
}
