import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { Collapse } from 'react-bootstrap'

import { urls } from 'app/utils/constants'

const permissions = JSON.parse(localStorage.getItem('listGroups'))

class Sidebar extends Component {
    state = {}

    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false })
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true })
        } else {
            Object.keys(this.state).forEach((i) => {
                this.setState({ [i]: false })
            })
            this.setState({ [menuState]: true })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged()
        }
    }

    onRouteChanged() {
        document.querySelector('#sidebar').classList.remove('active')
        Object.keys(this.state).forEach((i) => {
            this.setState({ [i]: false })
        })

        const dropdownPaths = [
            { path: '/apps', state: 'appsMenuOpen' },
            { path: '/basic-ui', state: 'basicUiMenuOpen' },
            { path: '/advanced-ui', state: 'advancedUiMenuOpen' },
            { path: '/form-elements', state: 'formElementsMenuOpen' },
            { path: '/tables', state: 'tablesMenuOpen' },
            { path: '/maps', state: 'mapsMenuOpen' },
            { path: '/icons', state: 'iconsMenuOpen' },

            { path: urls.park.INDEX, state: 'sitesMenuOpen' },
            { path: urls.products.INDEX, state: 'productsMenuOpen' },
            {
                path: urls.transactions.INDEX,
                state: 'transactionsMenuOpen',
            },
            { path: urls.operations.INDEX, state: 'operationsMenuOpen' },
            { path: urls.reports.INDEX, state: 'reportsMenuOpen' },
            { path: urls.users.INDEX, state: 'usersMenuOpen' },
            { path: urls.settings.INDEX, state: 'settingsMenuOpen' },
        ]

        dropdownPaths.forEach((obj) => {
            if (this.isPathActive(obj.path)) {
                this.setState({ [obj.state]: true })
            }
        })
    }

    getNavItemClass(path, isNavLink = false) {
        const navClass = isNavLink ? 'nav-link' : 'nav-item'
        return this.isPathActive(path) ? `${navClass} active` : navClass
    }

    render() {
        return (
            <nav
                className='sidebar sidebar-offcanvas positionFixedSidebar'
                id='sidebar'
            >
                <ul className='nav'>
                    <li className='nav-item nav-category'>
                        <Trans>Main</Trans>
                    </li>
                    <li className={this.getNavItemClass(urls.HOMEPAGE)}>
                        <Link
                            className='nav-link'
                            to={urls.HOMEPAGE}
                        >
                            <span className='icon-bg'>
                                <i className='mdi mdi-view-dashboard menu-icon'></i>
                            </span>
                            <span className='menu-title'>
                                <Trans>Dashboard</Trans>
                            </span>
                        </Link>
                    </li>

                    {/* Park */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_machine',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_site',
                            )) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.park.INDEX,
                                )}
                            >
                                {/* Collapse button */}
                                <div
                                    className={
                                        this.state.sitesMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState('sitesMenuOpen')
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-store menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Pool</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.sitesMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Clients */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_sitecustomer',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.park.clients
                                                                .LIST,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.park.clients
                                                                .LIST
                                                        }
                                                    >
                                                        <Trans>Clients</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Sites */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_site',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.park.sites
                                                                .INDEX,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.park.sites
                                                                .INDEX
                                                        }
                                                    >
                                                        <Trans>Sites</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Machines */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_machine',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.park.MACHINES,
                                                            true,
                                                        )}
                                                        to={urls.park.MACHINES}
                                                    >
                                                        <Trans>machines</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Planograms */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_machine',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.park.planograms
                                                                .INDEX,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.park.planograms
                                                                .INDEX
                                                        }
                                                    >
                                                        <Trans>
                                                            planograms
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </Collapse>
                            </li>
                        )}

                    <li className='nav-item nav-category'>
                        <Trans>Sales</Trans>
                    </li>

                    {/* Products */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_product',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_menu',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_discount',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_stats',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_dlc',
                            ) ||
                            (permissions.permissions?.find(
                                (elt) => elt.codename === 'view_pricetable',
                            ) &&
                                permissions.permissions?.find(
                                    (elt) => elt.codename === 'view_priceline',
                                ))) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.products.INDEX,
                                )}
                            >
                                <div
                                    className={
                                        this.state.productsMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState('productsMenuOpen')
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-cart menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Products</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.productsMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Suppliers */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_supplierproduct',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.products
                                                                .suppliers.LIST,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.products
                                                                .suppliers.LIST
                                                        }
                                                    >
                                                        <Trans>Suppliers</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Product list */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_product',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.products.LIST,
                                                            true,
                                                        )}
                                                        to={urls.products.LIST}
                                                    >
                                                        <Trans>
                                                            Products list
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Price table */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                (permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_pricetable',
                                                ) &&
                                                    permissions.permissions?.find(
                                                        (elt) =>
                                                            elt.codename ===
                                                            'view_priceline',
                                                    ))) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.products
                                                                .PRICE_TABLES,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.products
                                                                .PRICE_TABLES
                                                        }
                                                    >
                                                        <Trans>
                                                            Price table
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Reductions */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_discount',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.products
                                                                .REDUCTIONS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.products
                                                                .REDUCTIONS
                                                        }
                                                    >
                                                        <Trans>
                                                            Reductions
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Satistics */}
                                        <li className='nav-item'>
                                            <Link
                                                className={this.getNavItemClass(
                                                    urls.products.STATS,
                                                    true,
                                                )}
                                                to={urls.products.STATS}
                                            >
                                                <Trans>Stats</Trans>
                                            </Link>
                                        </li>
                                    </ul>
                                </Collapse>
                            </li>
                        )}

                    {/* Transactions */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_sale',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_creditrefill',
                            )) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.transactions.INDEX,
                                )}
                            >
                                <div
                                    className={
                                        this.state.transactionsMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState(
                                            'transactionsMenuOpen',
                                        )
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-transcribe menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Transactions</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.transactionsMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Sales */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_sale',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.transactions
                                                                .SALES,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.transactions
                                                                .SALES
                                                        }
                                                    >
                                                        <Trans>Sales</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Refills */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_creditrefill',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.transactions
                                                                .REFILLS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.transactions
                                                                .REFILLS
                                                        }
                                                    >
                                                        <Trans>Refills</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Refunds */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_refund',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.transactions
                                                                .REFUNDS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.transactions
                                                                .REFUNDS
                                                        }
                                                    >
                                                        <Trans>Refunds</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Blocked cards */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_adyenrefillpaiementstatus',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.transactions
                                                                .BLOCKED_CARDS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.transactions
                                                                .BLOCKED_CARDS
                                                        }
                                                    >
                                                        <Trans>
                                                            blockedCards
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </Collapse>
                            </li>
                        )}

                    {/* Operations */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_delivery',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_event',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_encodingreport',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_intervention',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_encodedproduct',
                            )) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.operations.INDEX,
                                )}
                            >
                                <div
                                    className={
                                        this.state.operationsMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState(
                                            'operationsMenuOpen',
                                        )
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-receipt menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Operations</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.operationsMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Delivery forms */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_delivery',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.operations
                                                                .DELIVERY_ORDERS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.operations
                                                                .DELIVERY_ORDERS
                                                        }
                                                    >
                                                        <Trans>
                                                            Delivery forms
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Incidents */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_event',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.operations
                                                                .INCIDENTS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.operations
                                                                .INCIDENTS
                                                        }
                                                    >
                                                        <Trans>Incidents</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* RabbitMQ incidents */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.operations
                                                                .RABBITMQ_INCIDENTS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.operations
                                                                .RABBITMQ_INCIDENTS
                                                        }
                                                    >
                                                        <Trans>
                                                            RabbitMQ incidents
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Technical interventions */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_encodingreport',
                                                ) ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_intervention',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.operations
                                                                .INTERVENTIONS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.operations
                                                                .INTERVENTIONS
                                                        }
                                                    >
                                                        <Trans>
                                                            Technical
                                                            interventions
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Production */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_encodedproduct',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.operations
                                                                .ENCODED_PRODUCTS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.operations
                                                                .ENCODED_PRODUCTS
                                                        }
                                                    >
                                                        <Trans>
                                                            Production
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Destocking */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_stockout',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.operations
                                                                .DESTOCKING,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.operations
                                                                .DESTOCKING
                                                        }
                                                    >
                                                        <Trans>
                                                            Destocking
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </Collapse>
                            </li>
                        )}

                    {/* Reports */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_product',
                            )) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.reports.INDEX,
                                )}
                            >
                                <div
                                    className={
                                        this.state.operationsMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState('reportsMenuOpen')
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-chart-line menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Reports</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.reportsMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Supply */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_product',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.reports.SUPPLY,
                                                            true,
                                                        )}
                                                        to={urls.reports.SUPPLY}
                                                    >
                                                        <Trans>supply</Trans>
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </Collapse>
                            </li>
                        )}

                    <li className='nav-item nav-category'>
                        <Trans>Personal</Trans>
                    </li>

                    {/* Users */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_deliveryman',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_technician',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_client',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_group',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_machineuser',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_user',
                            )) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.users.INDEX,
                                )}
                            >
                                <div
                                    className={
                                        this.state.usersMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState('usersMenuOpen')
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-account-multiple menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Users</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.usersMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Technicians */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_technician',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users
                                                                .TECHNICIANS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.users
                                                                .TECHNICIANS
                                                        }
                                                    >
                                                        <Trans>
                                                            Technicians
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Delivery men */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_deliveryman',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users
                                                                .DELIVERY_MEN,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.users
                                                                .DELIVERY_MEN
                                                        }
                                                    >
                                                        <Trans>
                                                            Delivery men
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Operators */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_client',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users.MANAGERS,
                                                            true,
                                                        )}
                                                        to={urls.users.MANAGERS}
                                                    >
                                                        <Trans>Operators</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Profiles */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_group',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users.PROFILES,
                                                            true,
                                                        )}
                                                        to={urls.users.PROFILES}
                                                    >
                                                        <Trans>Profiles</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Customers */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_machineuser',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users.KONVIVES,
                                                            true,
                                                        )}
                                                        to={urls.users.KONVIVES}
                                                    >
                                                        <Trans>Customers</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Console users */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_user',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users.konsole
                                                                .LIST,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.users.konsole
                                                                .LIST
                                                        }
                                                    >
                                                        <Trans>
                                                            {'usersKonsole'}
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Label guns */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_labelgun',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.users.PDA,
                                                            true,
                                                        )}
                                                        to={urls.users.PDA}
                                                    >
                                                        <Trans>
                                                            Label guns
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </Collapse>
                            </li>
                        )}

                    {/* Settings */}
                    {permissions &&
                        (permissions.is_staff ||
                            permissions.is_superuser ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_categoryclient',
                            ) ||
                            permissions.permissions?.find(
                                (elt) =>
                                    elt.codename === 'view_categoryproduct',
                            ) ||
                            permissions.permissions?.find(
                                (elt) =>
                                    elt.codename === 'view_subcategoryproduct',
                            ) ||
                            permissions.permissions?.find(
                                (elt) =>
                                    elt.codename === 'view_supplierproduct',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_sitetype',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_zone',
                            ) ||
                            permissions.permissions?.find(
                                (elt) => elt.codename === 'view_emailparameter',
                            )) && (
                            <li
                                className={this.getNavItemClass(
                                    urls.settings.INDEX,
                                )}
                            >
                                <div
                                    className={
                                        this.state.settingsMenuOpen
                                            ? 'nav-link menu-expanded'
                                            : 'nav-link'
                                    }
                                    onClick={() =>
                                        this.toggleMenuState('settingsMenuOpen')
                                    }
                                    data-toggle='collapse'
                                >
                                    <span className='icon-bg'>
                                        <i className='mdi mdi-settings menu-icon'></i>
                                    </span>
                                    <span className='menu-title'>
                                        <Trans>Settings</Trans>
                                    </span>
                                    <i className='menu-arrow'></i>
                                </div>

                                <Collapse in={this.state.settingsMenuOpen}>
                                    <ul className='nav flex-column sub-menu'>
                                        {/* Form options */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_categoryclient',
                                                ) ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_categoryproduct',
                                                ) ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_subcategoryproduct',
                                                ) ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_supplierproduct',
                                                ) ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_sitetype',
                                                ) ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_zone',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.settings.FORMS,
                                                            true,
                                                        )}
                                                        to={urls.settings.FORMS}
                                                    >
                                                        <Trans>
                                                            Form options
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Email */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_emailparameter',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.settings
                                                                .EMAILS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.settings.EMAILS
                                                        }
                                                    >
                                                        <Trans>Mail</Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Personalisation */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_colorsparameter',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.settings
                                                                .CUSTOMISATIONS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.settings
                                                                .CUSTOMISATIONS
                                                        }
                                                    >
                                                        <Trans>
                                                            Personalization
                                                        </Trans>
                                                    </Link>
                                                </li>
                                            )}

                                        {/* Ticket */}
                                        {permissions &&
                                            (permissions.is_staff ||
                                                permissions.is_superuser ||
                                                permissions.permissions?.find(
                                                    (elt) =>
                                                        elt.codename ===
                                                        'view_ticketparameter',
                                                )) && (
                                                <li className='nav-item'>
                                                    <Link
                                                        className={this.getNavItemClass(
                                                            urls.settings
                                                                .TICKETS,
                                                            true,
                                                        )}
                                                        to={
                                                            urls.settings
                                                                .TICKETS
                                                        }
                                                    >
                                                        <Trans>Ticket</Trans>
                                                    </Link>
                                                </li>
                                            )}
                                    </ul>
                                </Collapse>
                            </li>
                        )}
                </ul>

                <img
                    src={require('../../assets/images/powered-by-ethik.png')}
                    className='logoFooter'
                    alt=''
                />
            </nav>
        )
    }

    isPathActive(path) {
        return this.props.location.pathname.startsWith(path)
    }

    componentDidMount() {
        this.onRouteChanged()
        // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
        const body = document.querySelector('body')
        document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
            el.addEventListener('mouseover', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.add('hover-open')
                }
            })
            el.addEventListener('mouseout', function () {
                if (body.classList.contains('sidebar-icon-only')) {
                    el.classList.remove('hover-open')
                }
            })
        })
    }
}

export default withRouter(Sidebar)
