import * as terminalsStandard from 'app/mocks/data/park/machines/standard/terminals'

export const terminalDiagnostic1 = {
    machine: {
        id: terminalsStandard.terminalStandard1.id,
        type: terminalsStandard.terminalStandard1.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}

export const terminalDiagnostic2 = {
    machine: {
        id: terminalsStandard.terminalStandard2.id,
        type: terminalsStandard.terminalStandard2.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}

export const terminalDiagnostic3 = {
    machine: {
        id: terminalsStandard.terminalStandard3.id,
        type: terminalsStandard.terminalStandard3.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2009-12-27 08:26:49.219717',
        last_heartbeat_connection: '2010-12-27 08:26:49.219717',
    },
}
