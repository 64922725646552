import * as fridgesWeightStandard from 'app/mocks/data/park/machines/standard/fridgesWeight'
import * as sites from 'app/mocks/data/park/sites/linked'

export const fridgeWeight1 = {
    ...fridgesWeightStandard.fridgeWeightStandard1,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}

export const fridgeWeight2 = {
    ...fridgesWeightStandard.fridgeWeightStandard2,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}

export const fridgeWeight3 = {
    ...fridgesWeightStandard.fridgeWeightStandard3,
    site: {
        ...sites.site12,
        client: sites.site12.client.id,
    },
}
