import { roles } from 'app/utils/constants'

export const manager = {
    id: 22,
    name: roles.MANAGER, // Replace with the enum
    description: 'Gestionnaire de parcs machines',
    permissions: [
        {
            id: 361,
            name: 'Can add Adyen Paiement Status',
            codename: 'add_adyenpaiementstatus',
            content_type: 89,
        },
        {
            id: 362,
            name: 'Can change Adyen Paiement Status',
            codename: 'change_adyenpaiementstatus',
            content_type: 89,
        },
        {
            id: 363,
            name: 'Can delete Adyen Paiement Status',
            codename: 'delete_adyenpaiementstatus',
            content_type: 89,
        },
        {
            id: 364,
            name: 'Can view Adyen Paiement Status',
            codename: 'view_adyenpaiementstatus',
            content_type: 89,
        },
        {
            id: 365,
            name: 'Can add adyen refill paiement status',
            codename: 'add_adyenrefillpaiementstatus',
            content_type: 90,
        },
        {
            id: 366,
            name: 'Can change adyen refill paiement status',
            codename: 'change_adyenrefillpaiementstatus',
            content_type: 90,
        },
        {
            id: 367,
            name: 'Can delete adyen refill paiement status',
            codename: 'delete_adyenrefillpaiementstatus',
            content_type: 90,
        },
        {
            id: 368,
            name: 'Can view adyen refill paiement status',
            codename: 'view_adyenrefillpaiementstatus',
            content_type: 90,
        },
        {
            id: 12,
            name: 'Can view group',
            codename: 'view_group',
            content_type: 3,
        },
        {
            id: 8,
            name: 'Can view permission',
            codename: 'view_permission',
            content_type: 2,
        },
        {
            id: 13,
            name: 'Can add user',
            codename: 'add_user',
            content_type: 4,
        },
        {
            id: 14,
            name: 'Can change user',
            codename: 'change_user',
            content_type: 4,
        },
        {
            id: 15,
            name: 'Can delete user',
            codename: 'delete_user',
            content_type: 4,
        },
        {
            id: 16,
            name: 'Can view user',
            codename: 'view_user',
            content_type: 4,
        },
        {
            id: 357,
            name: 'Can add card blocked',
            codename: 'add_cardblocked',
            content_type: 88,
        },
        {
            id: 358,
            name: 'Can change card blocked',
            codename: 'change_cardblocked',
            content_type: 88,
        },
        {
            id: 359,
            name: 'Can delete card blocked',
            codename: 'delete_cardblocked',
            content_type: 88,
        },
        {
            id: 360,
            name: 'Can view card blocked',
            codename: 'view_cardblocked',
            content_type: 88,
        },
        {
            id: 373,
            name: 'Can add card blocked author',
            codename: 'add_cardblockedauthor',
            content_type: 92,
        },
        {
            id: 374,
            name: 'Can change card blocked author',
            codename: 'change_cardblockedauthor',
            content_type: 92,
        },
        {
            id: 375,
            name: 'Can delete card blocked author',
            codename: 'delete_cardblockedauthor',
            content_type: 92,
        },
        {
            id: 376,
            name: 'Can view card blocked author',
            codename: 'view_cardblockedauthor',
            content_type: 92,
        },
        {
            id: 165,
            name: 'Can add Allergen',
            codename: 'add_allergens',
            content_type: 42,
        },
        {
            id: 166,
            name: 'Can change Allergen',
            codename: 'change_allergens',
            content_type: 42,
        },
        {
            id: 167,
            name: 'Can delete Allergen',
            codename: 'delete_allergens',
            content_type: 42,
        },
        {
            id: 168,
            name: 'Can view Allergen',
            codename: 'view_allergens',
            content_type: 42,
        },
        {
            id: 121,
            name: 'Can add category client',
            codename: 'add_categoryclient',
            content_type: 31,
        },
        {
            id: 122,
            name: 'Can change category client',
            codename: 'change_categoryclient',
            content_type: 31,
        },
        {
            id: 123,
            name: 'Can delete category client',
            codename: 'delete_categoryclient',
            content_type: 31,
        },
        {
            id: 124,
            name: 'Can view category client',
            codename: 'view_categoryclient',
            content_type: 31,
        },
        {
            id: 53,
            name: 'Can add CategoryProduct',
            codename: 'add_categoryproduct',
            content_type: 14,
        },
        {
            id: 54,
            name: 'Can change CategoryProduct',
            codename: 'change_categoryproduct',
            content_type: 14,
        },
        {
            id: 55,
            name: 'Can delete CategoryProduct',
            codename: 'delete_categoryproduct',
            content_type: 14,
        },
        {
            id: 56,
            name: 'Can view CategoryProduct',
            codename: 'view_categoryproduct',
            content_type: 14,
        },
        {
            id: 46,
            name: 'Can change Client',
            codename: 'change_client',
            content_type: 12,
        },
        {
            id: 48,
            name: 'Can view Client',
            codename: 'view_client',
            content_type: 12,
        },
        {
            id: 269,
            name: 'Can add Colors_parameters',
            codename: 'add_colorsparameters',
            content_type: 66,
        },
        {
            id: 270,
            name: 'Can change Colors_parameters',
            codename: 'change_colorsparameters',
            content_type: 66,
        },
        {
            id: 272,
            name: 'Can view Colors_parameters',
            codename: 'view_colorsparameters',
            content_type: 66,
        },
        {
            id: 88,
            name: 'Can view Delivery',
            codename: 'view_delivery',
            content_type: 22,
        },
        {
            id: 42,
            name: 'Can change Deliveryman',
            codename: 'change_deliveryman',
            content_type: 11,
        },
        {
            id: 44,
            name: 'Can view Deliveryman',
            codename: 'view_deliveryman',
            content_type: 11,
        },
        {
            id: 129,
            name: 'Can add Discount',
            codename: 'add_discount',
            content_type: 33,
        },
        {
            id: 130,
            name: 'Can change Discount',
            codename: 'change_discount',
            content_type: 33,
        },
        {
            id: 131,
            name: 'Can delete Discount',
            codename: 'delete_discount',
            content_type: 33,
        },
        {
            id: 132,
            name: 'Can view Discount',
            codename: 'view_discount',
            content_type: 33,
        },
        {
            id: 225,
            name: 'Can add Discount priority',
            codename: 'add_discountpriority',
            content_type: 57,
        },
        {
            id: 226,
            name: 'Can change Discount priority',
            codename: 'change_discountpriority',
            content_type: 57,
        },
        {
            id: 227,
            name: 'Can delete Discount priority',
            codename: 'delete_discountpriority',
            content_type: 57,
        },
        {
            id: 228,
            name: 'Can view Discount priority',
            codename: 'view_discountpriority',
            content_type: 57,
        },
        {
            id: 171,
            name: 'Can delete Encoded product',
            codename: 'delete_encodedproduct',
            content_type: 43,
        },
        {
            id: 172,
            name: 'Can view Encoded product',
            codename: 'view_encodedproduct',
            content_type: 43,
        },
        {
            id: 200,
            name: 'Can view Encoding Report',
            codename: 'view_encodingreport',
            content_type: 50,
        },
        {
            id: 77,
            name: 'Can add MachineUser',
            codename: 'add_machineuser',
            content_type: 20,
        },
        {
            id: 78,
            name: 'Can change MachineUser',
            codename: 'change_machineuser',
            content_type: 20,
        },
        {
            id: 79,
            name: 'Can delete MachineUser',
            codename: 'delete_machineuser',
            content_type: 20,
        },
        {
            id: 80,
            name: 'Can view MachineUser',
            codename: 'view_machineuser',
            content_type: 20,
        },
        {
            id: 49,
            name: 'Can add Product',
            codename: 'add_product',
            content_type: 13,
        },
        {
            id: 50,
            name: 'Can change Product',
            codename: 'change_product',
            content_type: 13,
        },
        {
            id: 51,
            name: 'Can delete Product',
            codename: 'delete_product',
            content_type: 13,
        },
        {
            id: 52,
            name: 'Can view Product',
            codename: 'view_product',
            content_type: 13,
        },
        {
            id: 93,
            name: 'Can add Site',
            codename: 'add_site',
            content_type: 24,
        },
        {
            id: 94,
            name: 'Can change Site',
            codename: 'change_site',
            content_type: 24,
        },
        {
            id: 95,
            name: 'Can delete Site',
            codename: 'delete_site',
            content_type: 24,
        },
        {
            id: 96,
            name: 'Can view Site',
            codename: 'view_site',
            content_type: 24,
        },
        {
            id: 257,
            name: 'Can add Site Customer',
            codename: 'add_sitecustomer',
            content_type: 65,
        },
        {
            id: 258,
            name: 'Can change Site Customer',
            codename: 'change_sitecustomer',
            content_type: 65,
        },
        {
            id: 259,
            name: 'Can delete Site Customer',
            codename: 'delete_sitecustomer',
            content_type: 65,
        },
        {
            id: 260,
            name: 'Can view Site Customer',
            codename: 'view_sitecustomer',
            content_type: 65,
        },
        {
            id: 181,
            name: 'Can add Site Type',
            codename: 'add_sitetype',
            content_type: 46,
        },
        {
            id: 182,
            name: 'Can change Site Type',
            codename: 'change_sitetype',
            content_type: 46,
        },
        {
            id: 183,
            name: 'Can delete Site Type',
            codename: 'delete_sitetype',
            content_type: 46,
        },
        {
            id: 184,
            name: 'Can view Site Type',
            codename: 'view_sitetype',
            content_type: 46,
        },
        {
            id: 177,
            name: 'Can add SubCategoryProduct',
            codename: 'add_subcategoryproduct',
            content_type: 45,
        },
        {
            id: 178,
            name: 'Can change SubCategoryProduct',
            codename: 'change_subcategoryproduct',
            content_type: 45,
        },
        {
            id: 179,
            name: 'Can delete SubCategoryProduct',
            codename: 'delete_subcategoryproduct',
            content_type: 45,
        },
        {
            id: 180,
            name: 'Can view SubCategoryProduct',
            codename: 'view_subcategoryproduct',
            content_type: 45,
        },
        {
            id: 137,
            name: 'Can add product supplier',
            codename: 'add_supplierproduct',
            content_type: 35,
        },
        {
            id: 138,
            name: 'Can change product supplier',
            codename: 'change_supplierproduct',
            content_type: 35,
        },
        {
            id: 139,
            name: 'Can delete product supplier',
            codename: 'delete_supplierproduct',
            content_type: 35,
        },
        {
            id: 140,
            name: 'Can view product supplier',
            codename: 'view_supplierproduct',
            content_type: 35,
        },
        {
            id: 202,
            name: 'Can change Technician',
            codename: 'change_technician',
            content_type: 51,
        },
        {
            id: 204,
            name: 'Can view Technician',
            codename: 'view_technician',
            content_type: 51,
        },
        {
            id: 185,
            name: 'Can add Zone',
            codename: 'add_zone',
            content_type: 47,
        },
        {
            id: 186,
            name: 'Can change Zone',
            codename: 'change_zone',
            content_type: 47,
        },
        {
            id: 187,
            name: 'Can delete Zone',
            codename: 'delete_zone',
            content_type: 47,
        },
        {
            id: 188,
            name: 'Can view Zone',
            codename: 'view_zone',
            content_type: 47,
        },
        {
            id: 192,
            name: 'Can view Intervention',
            codename: 'view_delivery',
            content_type: 48,
        },
        {
            id: 26,
            name: 'Can change Machine',
            codename: 'change_machine',
            content_type: 7,
        },
        {
            id: 28,
            name: 'Can view Machine',
            codename: 'view_machine',
            content_type: 7,
        },
        {
            id: 301,
            name: 'Can add MachineGrid',
            codename: 'add_machinegrid',
            content_type: 74,
        },
        {
            id: 302,
            name: 'Can change MachineGrid',
            codename: 'change_machinegrid',
            content_type: 74,
        },
        {
            id: 303,
            name: 'Can delete MachineGrid',
            codename: 'delete_machinegrid',
            content_type: 74,
        },
        {
            id: 304,
            name: 'Can view MachineGrid',
            codename: 'view_machinegrid',
            content_type: 74,
        },
        {
            id: 305,
            name: 'Can add machine grid item',
            codename: 'add_machinegriditem',
            content_type: 75,
        },
        {
            id: 306,
            name: 'Can change machine grid item',
            codename: 'change_machinegriditem',
            content_type: 75,
        },
        {
            id: 307,
            name: 'Can delete machine grid item',
            codename: 'delete_machinegriditem',
            content_type: 75,
        },
        {
            id: 308,
            name: 'Can view machine grid item',
            codename: 'view_machinegriditem',
            content_type: 75,
        },
        {
            id: 309,
            name: 'Can add MachineGridItemPosition',
            codename: 'add_machinegriditemposition',
            content_type: 76,
        },
        {
            id: 310,
            name: 'Can change MachineGridItemPosition',
            codename: 'change_machinegriditemposition',
            content_type: 76,
        },
        {
            id: 311,
            name: 'Can delete MachineGridItemPosition',
            codename: 'delete_machinegriditemposition',
            content_type: 76,
        },
        {
            id: 312,
            name: 'Can view MachineGridItemPosition',
            codename: 'view_machinegriditemposition',
            content_type: 76,
        },
        {
            id: 153,
            name: 'Can add MachineOption',
            codename: 'add_machineoption',
            content_type: 39,
        },
        {
            id: 154,
            name: 'Can change MachineOption',
            codename: 'change_machineoption',
            content_type: 39,
        },
        {
            id: 155,
            name: 'Can delete MachineOption',
            codename: 'delete_machineoption',
            content_type: 39,
        },
        {
            id: 156,
            name: 'Can view MachineOption',
            codename: 'view_machineoption',
            content_type: 39,
        },
        {
            id: 57,
            name: 'Can add MarketingImage',
            codename: 'add_marketingimage',
            content_type: 15,
        },
        {
            id: 58,
            name: 'Can change MarketingImage',
            codename: 'change_marketingimage',
            content_type: 15,
        },
        {
            id: 59,
            name: 'Can delete MarketingImage',
            codename: 'delete_marketingimage',
            content_type: 15,
        },
        {
            id: 60,
            name: 'Can view MarketingImage',
            codename: 'view_marketingimage',
            content_type: 15,
        },
        {
            id: 313,
            name: 'Can add motor grid',
            codename: 'add_motorgrid',
            content_type: 77,
        },
        {
            id: 314,
            name: 'Can change motor grid',
            codename: 'change_motorgrid',
            content_type: 77,
        },
        {
            id: 315,
            name: 'Can delete motor grid',
            codename: 'delete_motorgrid',
            content_type: 77,
        },
        {
            id: 316,
            name: 'Can view motor grid',
            codename: 'view_motorgrid',
            content_type: 77,
        },
        {
            id: 92,
            name: 'Can view Stock',
            codename: 'view_stock',
            content_type: 23,
        },
        {
            id: 32,
            name: 'Can view TechnicalData',
            codename: 'view_technicaldata',
            content_type: 8,
        },
        {
            id: 205,
            name: 'Can add email parameter',
            codename: 'add_emailparameter',
            content_type: 52,
        },
        {
            id: 206,
            name: 'Can change email parameter',
            codename: 'change_emailparameter',
            content_type: 52,
        },
        {
            id: 208,
            name: 'Can view email parameter',
            codename: 'view_emailparameter',
            content_type: 52,
        },
        {
            id: 216,
            name: 'Can view Event',
            codename: 'view_event',
            content_type: 54,
        },
        {
            id: 141,
            name: 'Can add price line',
            codename: 'add_priceline',
            content_type: 36,
        },
        {
            id: 142,
            name: 'Can change price line',
            codename: 'change_priceline',
            content_type: 36,
        },
        {
            id: 143,
            name: 'Can delete price line',
            codename: 'delete_priceline',
            content_type: 36,
        },
        {
            id: 144,
            name: 'Can view price line',
            codename: 'view_priceline',
            content_type: 36,
        },
        {
            id: 145,
            name: 'Can add price table',
            codename: 'add_pricetable',
            content_type: 37,
        },
        {
            id: 146,
            name: 'Can change price table',
            codename: 'change_pricetable',
            content_type: 37,
        },
        {
            id: 147,
            name: 'Can delete price table',
            codename: 'delete_pricetable',
            content_type: 37,
        },
        {
            id: 148,
            name: 'Can view price table',
            codename: 'view_pricetable',
            content_type: 37,
        },
        {
            id: 164,
            name: 'Can view Dispute',
            codename: 'view_dispute',
            content_type: 41,
        },
        {
            id: 160,
            name: 'Can view Dispute Product',
            codename: 'view_disputeproduct',
            content_type: 40,
        },
        {
            id: 288,
            name: 'Can view Refund',
            codename: 'view_refund',
            content_type: 69,
        },
        {
            id: 112,
            name: 'Can view Sale',
            codename: 'view_sale',
            content_type: 28,
        },
        {
            id: 249,
            name: 'Can add Ticker Parameter',
            codename: 'add_ticketparameter',
            content_type: 63,
        },
        {
            id: 250,
            name: 'Can change Ticker Parameter',
            codename: 'change_ticketparameter',
            content_type: 63,
        },
        {
            id: 252,
            name: 'Can view Ticker Parameter',
            codename: 'view_ticketparameter',
            content_type: 63,
        },
        {
            id: 136,
            name: 'Can view profil',
            codename: 'view_profil',
            content_type: 34,
        },
    ],
}
