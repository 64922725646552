import * as lockersDryStandard from 'app/mocks/data/park/machines/standard/lockersDry'
import * as sites from 'app/mocks/data/park/sites/linked'

export const lockerDry1 = {
    ...lockersDryStandard.lockerDryStandard1,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}

export const lockerDry2 = {
    ...lockersDryStandard.lockerDryStandard2,
    site: {
        ...sites.site11,
        client: sites.site11.client.id,
    },
}

export const lockerDry3 = {
    ...lockersDryStandard.lockerDryStandard3,
    site: {
        ...sites.site16,
        client: sites.site16.client.id,
    },
}
