import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { Snackbar } from 'app/ui'
import { globalSnackberAtom } from 'app/recoil/atoms'
import { useGlobalSnackbarStore } from 'app/stores/globalSnackbar'

const GlobalSnackbar = () => {
    const { initGlobalSnackbar } = useGlobalSnackbarStore()

    const globalSnackbar = useRecoilValue(globalSnackberAtom)
    const [mounted, setMounted] = useState(false)
    const [show, setShow] = useState(false)

    useEffect(() => setMounted(true), [])

    // Initialise the setter of show for the global snackbar
    useEffect(() => {
        if (mounted) initGlobalSnackbar(setShow)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [mounted])

    return (
        <Snackbar
            show={show}
            setShow={setShow}
            variant={globalSnackbar.variant}
        >
            {globalSnackbar.text}
        </Snackbar>
    )
}

export default GlobalSnackbar
