import React from 'react'
import { makeStyles } from '@material-ui/core'

import { palette, themeMultiply, various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        padding: themeMultiply(various.padding, 2),
        color: palette.secondary.main,
        fontSize: '1.2em',
        textAlign: 'center',
        backgroundColor: palette.primary.main,
    },
})

export const TableHeaderCell = ({ className = '', ...props }) => {
    const classes = useStyles()

    return (
        <th
            className={`${classes.root} ${className}`}
            {...props}
        />
    )
}
