import { Trans, useTranslation } from 'react-i18next'
import { Link, makeStyles } from '@material-ui/core'
import { MdSupportAgent } from 'react-icons/md'

import { Card, Flex } from 'app/ui'
import { links } from 'app/utils/constants'
import { various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        maxWidth: '400px',
    },

    title: {
        paddingLeft: various.padding,
        fontSize: '2em',
        textAlign: 'center',
    },

    message: {
        marginTop: various.margin,
        fontSize: 'initial',
        fontWeight: 'initial',
        textAlign: 'justify',
    },
})

const TicketingLink = () => {
    const { t } = useTranslation()

    return (
        <Link
            href={links.TICKETING}
            target='_blank'
            rel='noreferrer'
        >
            {t('thisLink')}
        </Link>
    )
}

export const CustomerService = () => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Card className={classes.root}>
            <Flex>
                <MdSupportAgent size='2rem' />

                <div className={classes.title}>{t('customerService')}</div>
            </Flex>

            <div className={classes.message}>
                <Trans
                    i18nKey='customerServiceMessage'
                    components={{
                        link: <TicketingLink />,
                    }}
                />
            </div>
        </Card>
    )
}
