import { http, HttpResponse } from 'msw'

import { API_ENDPOINTS, isNumeric } from 'app/utils/constants'
import { allUsersKonsole } from 'app/mocks/data/users/konsole'

const getAllUsersKonsole = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.users.KONSOLE}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilter = url.searchParams.get('profil__client__in')
        const lastNameFilter = url.searchParams.get('last_name__icontains')
        const firstNameFilter = url.searchParams.get('first_name__icontains')
        const isAdminFilter = url.searchParams.get('is_staff')

        let allUsersFiltered = allUsersKonsole

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = managerIdsFilter.split(',')

            allUsersFiltered = allUsersFiltered.filter((user) =>
                user.profil.client.some((manager) =>
                    managerIdsSplit.includes(manager.id.toString()),
                ),
            )
        }

        // Last name filter
        if (!!lastNameFilter) {
            allUsersFiltered = allUsersFiltered.filter((user) =>
                user.last_name
                    .toLowerCase()
                    .includes(lastNameFilter.toLowerCase()),
            )
        }

        // First name filter
        if (!!firstNameFilter) {
            allUsersFiltered = allUsersFiltered.filter((user) =>
                user.first_name
                    .toLowerCase()
                    .includes(firstNameFilter.toLowerCase()),
            )
        }

        // Filter is admin
        if (!!isAdminFilter) {
            const isAdminValue = isAdminFilter === 'true'

            allUsersFiltered = allUsersFiltered.filter(
                (user) => user.is_staff === isAdminValue,
            )
        }

        // Pagination
        const limitArray =
            allUsersFiltered.length > limit
                ? offset + limit
                : allUsersFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allUsersFiltered.slice(offset, limitArray)
                : allUsersFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allUsersFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allUsersFiltered.length,
            next,
            results,
        })
    },
)

export default getAllUsersKonsole
