import * as fridgesWeightStandard from 'app/mocks/data/park/machines/standard/fridgesWeight'
import * as terminalsStandard from 'app/mocks/data/park/machines/standard/terminals'
import * as planogramsShort from 'app/mocks/data/park/planograms/short'

export const machineRelationShort1 = {
    id: 1,
    machine: terminalsStandard.terminalStandard1,
    distributors: [planogramsShort.planogramShort1],
    lockers: [planogramsShort.planogramShort2, planogramsShort.planogramShort3],
    weighted_fridges: [],
}

export const machineRelationShort2 = {
    id: 2,
    machine: fridgesWeightStandard.fridgeWeightStandard1,
    distributors: [],
    lockers: [],
    weighted_fridges: [planogramsShort.planogramShort4],
}

export const machineRelationShort3 = {
    id: 3,
    machine: fridgesWeightStandard.fridgeWeightStandard2,
    distributors: [],
    lockers: [],
    weighted_fridges: [planogramsShort.planogramShort5],
}

export const machineRelationShort4 = {
    id: 4,
    machine: terminalsStandard.terminalStandard2,
    distributors: [planogramsShort.planogramShort6],
    lockers: [planogramsShort.planogramShort7, planogramsShort.planogramShort8],
    weighted_fridges: [],
}

export const machineRelationShort5 = {
    id: 5,
    machine: fridgesWeightStandard.fridgeWeightStandard3,
    distributors: [],
    lockers: [],
    weighted_fridges: [planogramsShort.planogramShort9],
}

export const machineRelationShort6 = {
    id: 6,
    machine: terminalsStandard.terminalStandard3,
    distributors: [],
    lockers: [planogramsShort.planogramShort10],
    weighted_fridges: [],
}
