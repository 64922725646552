export const siteStandard1 = {
    id: 1,
    name: 'Carrefour Bruxelles',
    description: '',
    address: '2 Rue de la Madeleine',
    postal_code: 1000,
    city: 'Bruxelles',
    country: 'Belgique',
    building_floor: '',
    latitude: 50.8462237,
    longitude: 4.3547765,
    further_information: '',
    supply_days: 'mo,we,fr',
}

export const siteStandard2 = {
    id: 2,
    name: 'Carrefour Market Eurocontrol',
    description: '',
    address: '40 rue du marché aux poulets',
    postal_code: 1000,
    city: 'Bruxelles',
    country: 'Belgique',
    building_floor: '',
    latitude: 50.8486451,
    longitude: 4.3512768,
    further_information: '',
    supply_days: 'tu,th,sa',
}

export const siteStandard3 = {
    id: 3,
    name: 'Enedis Vannes',
    description: '',
    site_type: {
        id: 18,
        name: 'Ecole',
        priority: 2,
        client: 15,
    },
    zone: {
        id: 23,
        name: 'National',
        priority: 5,
        client: 15,
    },
    address: '9 rue des cerisiers',
    postal_code: 91090,
    city: 'Lisses',
    country: 'France',
    building_floor: '',
    latitude: 48.5966024,
    longitude: 2.4357425,
    further_information: '',
    supply_days: 'mo',
}

export const siteStandard4 = {
    id: 4,
    name: 'ethik',
    description: '',
    site_type: {
        id: 11,
        name: 'Association',
        priority: 5,
        client: 13,
    },
    zone: {
        id: 6,
        name: 'Nord',
        priority: 1,
        client: 13,
    },
    address: '103 Rue Charles Michels',
    postal_code: 93200,
    city: 'St Denis',
    country: 'FRANCE',
    building_floor: '',
    latitude: 48.9286312,
    longitude: 2.343926418704376,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa',
    opening_hours: '11:20:00',
    closing_hours: '11:20:00',
}

export const siteStandard5 = {
    id: 5,
    name: 'e-thik Show Room',
    description: 'Pizza',
    site_type: {
        id: 4,
        name: 'Tertiaire',
        priority: 1,
        client: 1,
    },
    zone: {
        id: 5,
        name: 'Ouest',
        priority: 1,
        client: 1,
    },
    address: '9 Rue des Cerisiers',
    postal_code: 91090,
    city: 'Lisses',
    country: 'FRANCE',
    building_floor: '',
    latitude: 48.5966024,
    longitude: 2.4357425,
    further_information: '',
    supply_days: 'mo,we,fr,th',
}

export const siteStandard6 = {
    id: 6,
    name: 'FAC DE SCIENCES  Bat A',
    description: '',
    site_type: {
        id: 48,
        name: 'Ecole',
        priority: 2,
        client: 21,
    },
    zone: {
        id: 54,
        name: 'Nord',
        priority: 1,
        client: 21,
    },
    address: '9',
    postal_code: 75000,
    city: 'Paris',
    country: 'France',
    building_floor: '',
    latitude: 48.8534951,
    longitude: 2.3483915,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard7 = {
    id: 7,
    name: 'FAC DE SCIENCES  Bat A',
    description: '',
    site_type: {
        id: 49,
        name: 'Administration',
        priority: 3,
        client: 21,
    },
    zone: {
        id: 55,
        name: 'Sud',
        priority: 2,
        client: 21,
    },
    address: '3',
    postal_code: 75000,
    city: 'Paris',
    country: 'France',
    building_floor: '',
    latitude: 48.84669875,
    longitude: 2.252604434669352,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard8 = {
    id: 8,
    name: 'FAC DE SCIENCES  Bat L',
    description: '',
    site_type: {
        id: 48,
        name: 'Ecole',
        priority: 2,
        client: 21,
    },
    zone: {
        id: 56,
        name: 'Est',
        priority: 3,
        client: 21,
    },
    address: '6',
    postal_code: 75000,
    city: 'Paris',
    country: 'France',
    building_floor: '',
    latitude: 48.8534951,
    longitude: 2.3483915,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard9 = {
    id: 9,
    name: 'Free Go chez JLP',
    description: '',
    site_type: {
        id: 47,
        name: 'Tertiaire',
        priority: 1,
        client: 21,
    },
    zone: {
        id: 57,
        name: 'Ouest',
        priority: 4,
        client: 21,
    },
    address: 'la borde',
    postal_code: 85600,
    city: 'Montaigu en vendée',
    country: 'France',
    building_floor: '',
    latitude: 46.937024050000005,
    longitude: -1.2442653609259362,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr',
}

export const siteStandard10 = {
    id: 10,
    name: 'Gare du Nord',
    description: 'Site de test 2',
    address: 'rue du nord',
    postal_code: 59000,
    city: 'Lille',
    country: 'France',
    latitude: 50.6693441,
    longitude: 2.9709494,
    supply_days: 'mo,sa',
}

export const siteStandard11 = {
    id: 11,
    name: 'graillance',
    description: '',
    site_type: {
        id: 67,
        name: 'Tertiaire',
        priority: 1,
        client: 25,
    },
    zone: {
        id: 78,
        name: 'Nord',
        priority: 1,
        client: 25,
    },
    address: '27 Rue Amédée Fraguier',
    postal_code: 91250,
    city: 'Tigery',
    country: 'France',
    building_floor: '',
    latitude: 48.6367067,
    longitude: 2.5102622,
    further_information: '',
    supply_days: 'mo',
}

export const siteStandard12 = {
    id: 12,
    name: 'KIS',
    description: '',
    site_type: {
        id: 27,
        name: 'Tertiaire',
        priority: 1,
        client: 17,
    },
    zone: {
        id: 31,
        name: 'Sud',
        priority: 2,
        client: 17,
    },
    address: '7 Rue Jean-Pierre Timbaud',
    postal_code: 38130,
    city: 'Échirolles',
    country: 'FRANCE',
    building_floor: '',
    latitude: 45.139498,
    longitude: 5.7108647,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr',
}

export const siteStandard13 = {
    id: 13,
    name: 'Labo',
    description: 'Frigo de test e-thik',
    site_type: {
        id: 4,
        name: 'Tertiaire',
        priority: 1,
        client: 1,
    },
    address: '1 rue Jules Serval',
    postal_code: 69259,
    city: 'Vénissieux',
    country: 'France',
    building_floor: '',
    latitude: 45.7076999,
    longitude: 4.8727913,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard14 = {
    id: 14,
    name: 'Mairie de Marcoussis',
    description: '',
    site_type: {
        id: 62,
        name: 'Tertiaire',
        priority: 1,
        client: 24,
    },
    zone: {
        id: 61,
        name: 'Sud',
        priority: 2,
        client: 22,
    },
    address: '10 rue des deux puits',
    postal_code: 91460,
    city: 'marcoussis',
    country: 'FRANCE',
    building_floor: '',
    latitude: 48.6395969,
    longitude: 2.227146,
    further_information: '',
    supply_days: 'mo',
}

export const siteStandard15 = {
    id: 15,
    name: 'Salon Europain',
    description: '',
    site_type: {
        id: 7,
        name: 'Tertiaire',
        priority: 1,
        client: 13,
    },
    zone: {
        id: 2,
        name: 'Centre',
        priority: 1,
        client: 1,
    },
    address: 'Porte de Versailles',
    postal_code: 750108,
    city: 'Paris',
    country: 'France',
    building_floor: '',
    latitude: 48.8324338,
    longitude: 2.2879537,
    further_information: '',
    supply_days: 'su,sa,fr,th,mo,tu',
}

export const siteStandard16 = {
    id: 16,
    name: 'sdf',
    description: '',
    site_type: {
        id: 7,
        name: 'Tertiaire',
        priority: 1,
        client: 13,
    },
    zone: {
        id: 2,
        name: 'Centre',
        priority: 1,
        client: 1,
    },
    address: 's',
    postal_code: 65432,
    city: 'sdf',
    country: 'sdf',
    building_floor: '',
    latitude: 25.30575475,
    longitude: 60.63166752453536,
    further_information: '',
    supply_days: 'mo',
}

export const siteStandard17 = {
    id: 17,
    name: 'Serbotel',
    description: '',
    site_type: {
        id: 67,
        name: 'Tertiaire',
        priority: 1,
        client: 25,
    },
    zone: {
        id: 81,
        name: 'Ouest',
        priority: 4,
        client: 25,
    },
    address: 'PArc des expositions',
    postal_code: 44000,
    city: 'NAntes',
    country: 'France',
    building_floor: '',
    latitude: 47.21976565,
    longitude: -1.542945387088528,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard18 = {
    id: 18,
    name: 'Sodebo chez Jlp',
    description: '',
    site_type: {
        id: 7,
        name: 'Tertiaire',
        priority: 1,
        client: 13,
    },
    zone: {
        id: 6,
        name: 'Nord',
        priority: 1,
        client: 13,
    },
    address: '9 rue des cerisiers',
    postal_code: 91090,
    city: 'Lisses',
    country: 'France',
    building_floor: '',
    latitude: 48.5966024,
    longitude: 2.4357425,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr',
}

export const siteStandard19 = {
    id: 19,
    name: 'test',
    description: 'test',
    site_type: {
        id: 5,
        name: 'Secondaire',
        priority: 1,
        client: 1,
    },
    zone: {
        id: 2,
        name: 'Centre',
        priority: 1,
        client: 1,
    },
    address: '13 All. François Mitterrand',
    postal_code: 49100,
    city: 'ANGERS',
    country: 'France',
    building_floor: '',
    latitude: 47.4786424,
    longitude: -0.5491213,
    further_information: '',
    supply_days: 'mo,tu,we,th,fr,sa,su',
}

export const siteStandard20 = {
    id: 20,
    name: 'test',
    description: 'WebHook for the credit refill of Konvives',
    site_type: {
        id: 5,
        name: 'Secondaire',
        priority: 1,
        client: 1,
    },
    zone: {
        id: 2,
        name: 'Centre',
        priority: 1,
        client: 1,
    },
    address: 'Av. Minerve 21',
    postal_code: 1450,
    city: 'Chastre',
    country: 'BELGIUM',
    building_floor: '',
    latitude: 50.606682500000005,
    longitude: 4.643459914745515,
    further_information: '',
    supply_days: 'mo,tu,we,fr,th,sa,su',
}

export const siteStandard21 = {
    id: 21,
    name: 'test',
    description: 'WebHook for the credit refill of Konvives',
    site_type: {
        id: 5,
        name: 'Secondaire',
        priority: 1,
        client: 1,
    },
    zone: {
        id: 2,
        name: 'Centre',
        priority: 1,
        client: 1,
    },
    address: 'Av. Minerve 21',
    postal_code: 1450,
    city: 'Chastre',
    country: 'BELGIUM',
    building_floor: '',
    latitude: 50.606682500000005,
    longitude: 4.643459914745515,
    further_information: '',
    supply_days: 'mo,tu,we,fr,th,sa,su',
}
