import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allSuppliers } from 'app/mocks/data/products/suppliers'
import { API_ENDPOINTS } from 'app/utils/constants'

const deleteSupplier = http.delete(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.products.SUPPLIERS}:id/`,
    ({ params }) => {
        const { id } = params

        const supplierFound = allSuppliers.find(
            (supplier) => supplier.id.toString() === id,
        )

        // Not found
        if (!supplierFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return new HttpResponse(null, {
            status: StatusCodes.NO_CONTENT,
        })
    },
)

export default deleteSupplier
