import { atom } from 'recoil'

/**
 * Global snackbar type.
 * @typedef {Object} GlobalSnackbarAtom
 * @property {Dispatch<SetStateAction<boolean>>} setShow
 * @property {'info' | 'success' | 'error'} [variant]
 * @property {string} [text]
 */
export const globalSnackberAtom = atom({
    key: 'globalSnackbarAtom',
    /** @type GlobalSnackbarAtom */
    default: {
        setShow: () => {
            /* Empty */
        },
    },
})
