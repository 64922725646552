import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allMachines } from 'app/mocks/data/park/machines'
import { API_ENDPOINTS } from 'app/utils/constants'

const updateMachine = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.park.MACHINES}:id/`,
    ({ request, params }) => {
        const data = request.json()
        const { id } = params

        const machineFound = allMachines.find(
            (machine) => machine.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!machineFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const machineToReturn = {
            ...data,
            ...machineFound,
        }

        return HttpResponse.json(machineToReturn)
    },
)

export default updateMachine
