import React from 'react'
import { makeStyles } from '@material-ui/core'

import { various } from 'app/utils/theme'

const useStyles = makeStyles({
    root: {
        padding: various.padding,
        textAlign: 'center',
        borderBottom: '1px solid grey',
    },

    content: {
        display: 'flex',
        justifyContent: 'center',
    },
})

export const TableCell = ({ className = '', ...props }) => {
    const classes = useStyles()

    return (
        <td
            className={`${classes.root} ${className}`}
            {...props}
        />
    )
}
