import * as terminalsStandard from 'app/mocks/data/park/machines/standard/terminals'
import * as sites from 'app/mocks/data/park/sites/linked'

export const terminal1 = {
    ...terminalsStandard.terminalStandard1,
    site: {
        ...sites.site1,
        client: sites.site1.client.id,
    },
}

export const terminal2 = {
    ...terminalsStandard.terminalStandard2,
    site: {
        ...sites.site11,
        client: sites.site11.client.id,
    },
}

export const terminal3 = {
    ...terminalsStandard.terminalStandard3,
    site: {
        ...sites.site16,
        client: sites.site16.client.id,
    },
}
