import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles({
    root: {
        overflow: 'auto',
    },

    table: {
        width: '100%',
    },
})

export const Table = ({ className = '', ...props }) => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <table
                className={`${classes.table} ${className}`}
                {...props}
            />
        </div>
    )
}
