import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { createId } from '@paralleldrive/cuid2'
import {
    MdCheckCircleOutline,
    MdErrorOutline,
    MdInfoOutline,
} from 'react-icons/md'
import { palette, various } from 'app/utils/theme'

const TOP_START = '-200px'
const TOP_END = '10px'

const useStyles = makeStyles({
    root: {
        zIndex: 10_000,
        position: 'fixed',
        display: 'none',
        alignItems: 'center',
        top: TOP_END,
        left: '50%',
        transform: 'translate(-50%, 0)',
        padding: '10px 25px',
        color: palette.text.primary,
        borderRadius: various.borderRadius,
    },

    success: {
        backgroundColor: palette.success.main,
    },

    error: {
        backgroundColor: palette.error.main,
    },

    info: {
        backgroundColor: palette.grey[50],
    },

    show: {
        display: 'inline-flex',
        animation: `$fadein 0.5s, $fadeout 0.5s 4.5s`,
    },

    text: {
        marginLeft: '10px',
        textAlign: 'center',
    },

    '@keyframes fadein': {
        '0%': {
            top: TOP_START,
            opacity: 0,
        },
        '100%': {
            top: TOP_END,
            opacity: 1,
        },
    },

    '@keyframes fadeout': {
        '0%': {
            top: TOP_END,
            opacity: 1,
        },
        '100%': {
            top: TOP_START,
            opacity: 0,
        },
    },
})

export const Snackbar = ({ children, show, setShow, variant = 'info' }) => {
    const classes = useStyles()

    const [id, setId] = useState()

    const styleVariant = () => {
        if (variant === 'success') return classes.success
        if (variant === 'error') return classes.error

        return classes.info
    }

    useEffect(() => setId(createId()), [])

    useEffect(() => {
        if (show) setShow(false)
    }, [show, setShow])

    // Show snackbar
    useEffect(() => {
        const showSnackbar = async () => {
            const allSnackbar = document.querySelectorAll(`.${classes.show}`)
            allSnackbar.forEach((snackbar) =>
                snackbar.classList.remove(classes.show),
            )

            await new Promise((resolve) => setTimeout(resolve, 50))

            const snackbar = document.querySelector(`#${id || ''}`)
            snackbar && snackbar.classList.add(classes.show)

            await new Promise((resolve) => setTimeout(resolve, 4_950))

            snackbar && snackbar.classList.remove(classes.show)
        }

        if (show)
            showSnackbar().catch(() => {
                /* Empty */
            })
    }, [show, setShow, id])

    return (
        <div
            id={id}
            className={`${classes.root} ${styleVariant()}`}
        >
            {variant === 'info' && <MdInfoOutline size='1.5em' />}
            {variant === 'success' && <MdCheckCircleOutline size='1.5em' />}
            {variant === 'error' && <MdErrorOutline size='1.5em' />}

            <div className={classes.text}>{children}</div>
        </div>
    )
}
