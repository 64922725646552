import * as admins from './admins'
import * as lambdas from './lambdas'

export const allUsersKonsole = [
    ...Object.values(admins),
    ...Object.values(lambdas),
].sort(
    (previous, current) =>
        previous.last_name.localeCompare(current.last_name) ||
        previous.first_name.localeCompare(current.first_name),
)
