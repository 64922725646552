const primaryRawMain = '240, 40%, 20%'
const secondaryRawMain = '160, 75%, 80%'

/** Theme colour palette. */
export const palette = {
    primaryRaw: {
        main: '240, 40%, 20%',
    },

    primary: {
        main: `hsl(${primaryRawMain})`,
    },

    secondaryRaw: {
        main: '160, 75%, 80%',
    },

    secondary: {
        main: `hsl(${secondaryRawMain})`,
    },

    text: {
        primary: 'hsl(0, 0%, 5%)',
        secondary: 'hsl(0, 0%, 95%)',
    },

    background: {
        primary: 'hsl(0, 0%, 95%)',
    },

    success: {
        main: 'hsl(120, 40%, 50%)',
        gradient:
            'linear-gradient(to bottom right, hsl(120, 40%, 45%), hsl(120, 40%, 60%))',
    },

    error: {
        main: 'hsl(5, 90%, 60%)',
        gradient:
            'linear-gradient(to bottom right, hsl(5, 90%, 55%), hsl(5, 90%, 65%))',
    },

    grey: {
        10: 'hsl(0, 0%, 10%)',
        20: 'hsl(0, 0%, 20%)',
        30: 'hsl(0, 0%, 30%)',
        40: 'hsl(0, 0%, 40%)',
        50: 'hsl(0, 0%, 50%)',
        60: 'hsl(0, 0%, 60%)',
        70: 'hsl(0, 0%, 70%)',
        80: 'hsl(0, 0%, 80%)',
        90: 'hsl(0, 0%, 90%)',
    },

    blue: {
        main: 'hsl(240, 50%, 30%)',
        gradient:
            'linear-gradient(to bottom right, hsl(240, 100%, 25%), hsl(240, 100%, 35%))',
    },

    orange: {
        main: 'hsl(30, 80%, 55%)',
        gradient:
            'linear-gradient(to bottom right, hsl(30, 80%, 45%), hsl(30, 90%, 65%))',
    },

    purple: {
        main: 'hsl(300, 100%, 30%)',
    },

    taupe: {
        main: 'hsl(30, 15%, 45%)',
        gradient:
            'linear-gradient(to bottom right, hsl(30, 15%, 35%), hsl(30, 15%, 55%))',
    },

    turquoise: {
        main: 'hsl(175, 75%, 55%)',
        gradient:
            'linear-gradient(to bottom right, hsl(175, 75%, 45%), hsl(175, 75%, 65%))',
    },
}

/** Various theme values. */
export const various = {
    borderRadius: '10px',
    margin: '20px',
    padding: '15px',
    boxShadow: {
        component: '2px 2px 5px hsla(0, 0%, 0%, 0.4)',
    },
}

/** Multiply a theme value (wait for Vanilla extract). */
export const themeMultiply = (value, multiplication) => {
    const digit = parseFloat(value)
    const unit = value.match(/[a-zA-Z]/g).join('')

    return `${digit * multiplication}${unit}`
}

/** Return the class name to use without falsy values. */
export const joinStyles = (...styles) => {
    return styles.filter(Boolean).join(' ')
}
