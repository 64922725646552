import { machineType } from 'app/utils/enums'

export const fridgeWeightStandard1 = {
    id: 301,
    name: 'Fridge Weight 1 Mock',
    category: machineType.Enum.FRIDGE_WEIGHT,
    description: '',
    stock_usage_percentage: 1,
    number_of_products: 4,
    max_products: 300,
    software_version: '1.14',
    build_date: '2023-07-17',
    installation_date: '2023-07-17',
    distributor_volume: 300,
    connexion_type: 'et',
    paiement_method: 'cb,am',
    enabled: true,
    online: true,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 100,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    paygreen_shop_id: null,
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    shekel_id: 'lejhsc8cT6CkoAJ3nsxDog',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 157,
    components: [],
    has_ups: true,
}

export const fridgeWeightStandard2 = {
    id: 302,
    name: 'Fridge Weight 2 Mock',
    category: machineType.Enum.FRIDGE_WEIGHT,
    description: '',
    stock_usage_percentage: 1,
    number_of_products: 4,
    max_products: 300,
    software_version: '1.14',
    build_date: '2023-07-17',
    installation_date: '2023-07-17',
    distributor_volume: 300,
    connexion_type: 'et',
    paiement_method: 'cb,am',
    enabled: true,
    online: true,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 100,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    shekel_id: 'lejhsc8cT6CkoAJ3nsxDog',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 157,
    components: [],
    has_ups: true,
}

export const fridgeWeightStandard3 = {
    id: 303,
    name: 'Fridge Weight 3 Mock',
    category: machineType.Enum.FRIDGE_WEIGHT,
    description: '',
    stock_usage_percentage: 1,
    number_of_products: 4,
    max_products: 300,
    software_version: '1.14',
    build_date: '2023-07-17',
    installation_date: '2023-07-17',
    distributor_volume: 300,
    connexion_type: 'et',
    paiement_method: 'cb,am',
    enabled: true,
    online: true,
    is_low_stock: true,
    is_high_stock: false,
    percentage_stock_alarm: 10,
    percentage_high_stock_alarm: 100,
    time_high_stock_check: '00:00:00',
    minimum_credit: 10.0,
    payment_priority: 'CP,CB,TRD',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    shekel_id: 'lejhsc8cT6CkoAJ3nsxDog',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    user: 157,
    components: [],
    has_ups: false,
}
