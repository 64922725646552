import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allManagers } from 'app/mocks/data/users/managers'
import { API_ENDPOINTS } from 'app/utils/constants'

const updateManager = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${API_ENDPOINTS.managers.LIST}:id/`,
    async ({ params, request }) => {
        const data = await request.json()
        const { id } = params

        const managerFound = allManagers.find(
            (manager) => manager.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!managerFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const managerUpdated = {
            ...managerFound,
            ...data,
        }

        return HttpResponse.json(managerUpdated)
    },
)

export default updateManager
