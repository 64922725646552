import { makeStyles } from '@material-ui/core'

import { joinStyles, palette } from 'app/utils/theme'

const useStyles = makeStyles({
    base: {
        aspectRatio: '1',
        borderRadius: '50%',
    },

    small: {
        width: '0.5rem',
        margin: '5px',
    },

    medium: {
        width: '1rem',
        margin: '10px',
    },

    large: {
        width: '1.5rem',
        margin: '15px',
    },

    error: {
        backgroundColor: palette.error.main,
    },

    orange: {
        backgroundColor: palette.orange.main,
    },

    success: {
        backgroundColor: palette.success.main,
    },
})

/** Dot UI component. */
export const Dot = ({
    className,
    size = 'medium',
    color = 'success',
    ...properties
}) => {
    const classes = useStyles()

    return (
        <div
            className={joinStyles(
                classes.base,
                classes[size],
                classes[color],
                className,
            )}
            {...properties}
        />
    )
}
