import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Typography,
    makeStyles,
    Button,
} from '@material-ui/core'

import { Trans } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(5),
    },
    dialogTitle: {
        textAlign: 'center',
    },
    dialogContent: {
        textAlign: 'center',
    },
    dialogAction: {
        justifyContent: 'center',
    },
    titleIcon: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.main,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            cursor: 'default',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '8rem',
        },
    },
}))

export default function ConfirmDialog(props) {
    const { confirmDialog, setConfirmDialog } = props
    const classes = useStyles()

    return (
        <Dialog
            open={confirmDialog.isOpen}
            classes={{ paper: classes.dialog }}
        >
            <DialogTitle className={classes.dialogTitle}></DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant='h6'>
                    <Trans>{confirmDialog.title}</Trans>
                </Typography>
                <Typography variant='subtitle2'>
                    <Trans>{confirmDialog.subTitle}</Trans>
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button
                    onClick={() =>
                        setConfirmDialog({ ...confirmDialog, isOpen: false })
                    }
                >
                    <Trans>No</Trans>
                </Button>
                <Button onClick={confirmDialog.onConfirm}>
                    <Trans>Yes</Trans>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
